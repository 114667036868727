header#myHeader, .btn-degrated {
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(203,203,204,1) 58%, rgba(148,148,149,1) 100%);
    }
    section.jumbotron{
    font-weight: normal;
    background-size: cover;
    }
    .btn-main {
    box-shadow:inset 0px 1px 0px 0px #ededed;
    background:linear-gradient(to bottom, #ededed 5%, #979798 100%);
    background-color:#ededed;
    border-radius:2px;
    border:1px solid #dcdcdc;
    display:inline-block;
    cursor:pointer;
    color:#000000 !important;
    font-weight:bold;
    padding:6px 24px;
    text-decoration:none;
    }
    footer.footer-light .subfooter .social-icons span i {
    background: #000;
    }
    .navbar .navbar-item .lines {
    border-bottom: 2px solid #000;
    }
    .jumbotron h1{
    color: #fff;
    }
    .jumbotron h2 .color {
    color: #fff;
    }
    .jumbotron .btn-main, .btn-black{
    text-transform: uppercase;
    font-size: 24px;
    padding: 8px 40px;
    }
    .btn-main.rounded-0{
    padding: 8px 40px;
    border-color: #000;
    }
    input::placeholder {
        color: #000;
    }
    input.bg-dark::placeholder{
        color:#fff;
    }
    .form-select{
    padding: 8px 25px 8px 10px;
    }
    .navbar{
    border-bottom: 1px solid #000;
    }
    .feature-box.style-3{
    padding:20px;
    }
    .feature-box.style-3 i{
    background: none;
    padding:0px;
    height: 100%;
    width: 30%;
    float: left;
    }
    .feature-box.f-boxed.style-3 {
    background: rgb(251,252,252);
    background: linear-gradient(180deg, rgba(251,252,252,1) 0%, rgba(153,153,155,1) 100%);
    }
    .feature-box.f-boxed.style-3 i.wm{
    display: none;
    }
    .feature-box.style-3 p{
    color: #000;
    font-weight: 500;
    font-size: 14px;
    }
    .feature-box.style-3 .text{
    padding-left: 30%;
    }
    .feature-box.style-3 .icon_wallet:before{
    content: '';
    background: url(../img/money.svg);
    width: 100px;
    height: 100px;
    display: block;
    background-position: 0;
    background-repeat: no-repeat;
    }
    .feature-box.style-3 .icon_key_alt:before{
    content: '';
    background: url(../img/lucky.svg);
    width: 100px;
    height: 100px;
    display: block;
    background-position: 0;
    background-repeat: no-repeat;
    }
    .feature-box.style-3 .social_share:before{
    content: '';
    background: url(../img/share.svg);
    width: 100px;
    height: 100px;
    display: block;
    background-position: 0;
    background-repeat: no-repeat;
    }
    footer.footer-light #form_subscribe #btn-subscribe i{
    box-shadow: inset 0px 1px 0px 0px #ededed;
    background: linear-gradient(to bottom, #ededed 5%, #979798 100%);
    background-color: #ededed;
    display: inline-block;
    cursor: pointer;
    color: #000000 !important;
    font-weight: bold;
    text-decoration: none;
    border-top: #000 solid thin;
    border-right: #000 solid thin;
    border-bottom: #000 solid thin;
    padding: 4px;
    }
    .overflow-scroll{
    max-height: 400px;
    overflow-x: visible !important;
    }
    .overflow-scroll::-webkit-scrollbar{
    background: black;
    color: #fff;
    }
    .icongreen{
    color: #0bdf31;
    }
    .iconyellow{
    color: #d99506;
    }
    .iconred{
    color: red;
    }
    .bg-dark{
    background-color: #000 !important;
    }
    ::-webkit-scrollbar-thumb {
    background: rgb(251,252,252);
    background: linear-gradient(180deg, rgba(251,252,252,1) 0%, rgba(153,153,155,1) 100%);
    }
    .breadcumb:before {
    display: none;
    }
    .breadcumb.l-vh{
    margin-top: 0px;
    padding-top: 80px;
    }
    .jumbotron p {
    font-weight: 400;
    }
    .table>:not(caption)>*>* {
    background-color: rgba(0,0,0,0.5);
    }
    .card-body a.btn{
    background-color: #8364E2;
    color: #fff;
    padding: 8px 15px;
    }
    .card-body .card-text{
        color: #000;
    }
    .card.shadow.rounded{
        padding: 5px;
    }
    input.form-control{
    border-radius: 0;
    }
    option{
        background-color: #fff !important;
     }
    .border-dark::before {
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background-image: linear-gradient(to left, #8364E2, #403f83);
        opacity: 0;
        z-index: 1;
        transition: all 0.3s ease;
    }
    .de_nav li span.btn-main {
        box-shadow: inset 0px 1px 0px 0px #ededed;
        background: linear-gradient(to bottom, #ededed 5%, #979798 100%);
        background-color: #ededed;
        border-radius: 2px;
        border: 1px solid #dcdcdc;
        display: inline-block;
        cursor: pointer;
        color: #000000 !important;
        font-weight: bold;
        padding: 6px 24px;
        text-decoration: none;
    }
    .form-control.bg-black{
        color: #fff;
    }

    p.kickstat {
        font-size: 26px;
        line-height: 1.2em;
        margin-top: 0;
        font-weight: 700;
      }

    .btn-small {
        font-size: .8em;
    }

    .select-search {
        color: #fff !important;
        background-color: #000 !important;
        -webkit-appearance: none;
    }

    @media only screen and (max-width: 1199px){
        .navbar.white a, .navbar.navbar .dropdown-custom.btn {
        color: #000;
    }}

    @media (max-width: 767px) {                  
      .mobile-hide {
         display: none;
      }
   }

.card-random {
    border-bottom: 1px solid #90A4AE; 
    padding-bottom: 4px; 
}

    /* common */
.ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
  }
  .ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #2980b9;
  }
  .ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: #3498db;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font: 700 18px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
  }
  
  .ribbon-success span {
    background-color: #047009;
  }

  .ribbon-warning span {
    background-color: #eee347;
    color: rgb(24, 24, 24);
  }

  .ribbon-danger span {
    background-color: #db344a;
  }
  /* top left*/
  .ribbon-top-left {
    top: -10px;
    left: -10px;
  }
  .ribbon-top-left::before,
  .ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-top-left::before {
    top: 0;
    right: 0;
  }
  .ribbon-top-left::after {
    bottom: 0;
    left: 0;
  }
  .ribbon-top-left span {
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
  }
  
  /* top right*/
  .ribbon-top-right {
    top: -10px;
    right: -10px;
  }
  .ribbon-top-right::before,
  .ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent;
  }
  .ribbon-top-right::before {
    top: 0;
    left: 0;
  }
  .ribbon-top-right::after {
    bottom: 0;
    right: 0;
  }
  .ribbon-top-right span {
    left: -25px;
    top: 30px;
    transform: rotate(45deg);
  }
  
  h3.launcher {
    font-size: 32px;
    margin-bottom: 2px;
    margin-top:10px;
    color:#f6f6f6;
  }
  h3.joinkick {
    font-size: 26px;
    margin-bottom: 0px;
    margin-top:10px;
    color:#f6f6f6;
  }
